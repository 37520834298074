
.App {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding: 1em 2em;


  .BackgroundVideo {
    display: inline-block;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
    object-fit: fill;
    filter: blur(15px);
  }


  .Header {
    display: flex;
    flex-direction: row;
    //width: 100%;
    height: 15%;
    padding: 2em 0;

    .Logo {
      display: flex;
      width: 15%;
      max-width: 322px;
      align-items: self-start;

      img {
        object-fit: contain;
        height: 100%;
        max-width: 100%;
        max-height: 70px;
      }
    }

    .Menu {
      display: flex;
      flex: 1;
      font-size: 2.1em;

      ul {
        display: flex;
        flex-direction: row;
        padding: 0 .8em;

        li {
          padding: 0 0.7em;
          display: flex;
          align-items: stretch;

          a {
            display: flex;
            color: #fff;
            align-items: center;
            text-decoration: none;
            opacity: 75%;

            &:hover {
              opacity: 100%;
              transform: scale(1.1);
            }

            &.active {
              opacity: 100%;
              transform: scale(1.1);
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .Main {
    display: flex;
    flex-direction: row;
    //width: 100%;
    height: 75%;
  }

  .Footer {
    display: flex;
    flex-direction: row;
    //width: 100%;
    height: 10%;
    //background: purple;
  }
}