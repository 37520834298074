@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/NotoSans-Bold.woff2') format('woff2'),
  url('./fonts/NotoSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/NotoSans-Regular.woff2') format('woff2'),
  url('./fonts/NotoSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/NotoSans-Thin.woff2') format('woff2'),
  url('./fonts/NotoSans-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  src: url('./fonts/NotoSans-SemiBold.woff2') format('woff2'),
  url('./fonts/NotoSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


*, ::before, ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}

html,body, #root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Noto Sans", sans-serif;
  overflow: hidden;
}

body {
  line-height: 1;
}

h1,h2,h3,h4,h5,h6,h7 {
  line-height: 1;
  padding: 0;
  margin: 0;
}

ol, ul, menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
