.Home {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  gap: 40px;

  .HomeBlock {
    display: flex;
    flex: 1;
    flex-direction: column;
    color: #fff;
    gap: 40px;

    &:first-child {
      margin-left: 0;
    }

    .HomeButton {
      position: relative;
      z-index: 1;
      display: flex;
      flex: 1;
      padding: 1em;
      color: #fff;
      text-decoration: none;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      box-shadow: 0 0 30px rgba(#000,.7);

      h1, h2 {
        text-transform: uppercase;
        font-weight: normal;
      }

      h1 {
        font-size: 2.2em;
      }

      h2 {
        display: none;
        font-size: 1.7em;
        margin-top: .5em;
        color: #FFAF00;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        //background: #fff;
        z-index: 9;
        display: none;
        box-shadow:inset 0px 0px 0px 5px #fff;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to bottom, transparent 0%, black 100%);
      }

      &:hover {
        &:before {
          display: block;
        }

        transform: scale(1.07);

        h2 {
          display: inline-block;
        }
      }

      &.HomeButtonNewGame {
        background-image: url("./images/newgame.png");
      }

      &.HomeButtonLeaderboard {
        background-image: url("./images/leaderboard.png");
      }

      &.HomeButtonTrackBuilder {
        background-image: url("./images/tb.jpg");
      }

      &.HomeButtonApp {
        background-image: url("./images/app.png");
      }

      .HBText {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        z-index: 10;
      }
    }



  }
}
